import {
  InformationCircleIcon,
  ChartBarIcon,
  SunIcon,
} from '@heroicons/react/outline'
import { useState, useEffect } from 'react'
import { Alert } from './components/alerts/Alert'
import { Grid } from './components/grid/Grid'
import { Keyboard } from './components/keyboard/Keyboard'
import { AboutModal } from './components/modals/AboutModal'
import { InfoModal } from './components/modals/InfoModal'
import { StatsModal } from './components/modals/StatsModal'
import { ArchiveButtons } from './components/archive/Buttons'
import {
  GAME_TITLE,
  WIN_MESSAGES,
  GAME_COPIED_MESSAGE,
  ABOUT_GAME_MESSAGE,
  NOT_ENOUGH_LETTERS_MESSAGE,
  WORD_NOT_FOUND_MESSAGE,
  CORRECT_WORD_MESSAGE,
} from './constants/strings'
import { isWordInWordList, isWinningWord, getWordOfDay, solutionIndex, currentSolutionIndex } from './lib/words'
import { addStatsForCompletedGame, loadStats } from './lib/stats'
import {
  loadGameStateFromLocalStorage,
  saveGameStateToLocalStorage,
} from './lib/localStorage'

import './App.css'

import ReactGA from 'react-ga'
const TRACKING_ID = 'G-HPT8YBXY4Q' // YOUR_OWN_TRACKING_ID
ReactGA.initialize(TRACKING_ID)
ReactGA.pageview(window.location.pathname)

const ALERT_TIME_MS = 2000

function App() {
  const prefersDarkMode = window.matchMedia(
    '(prefers-color-scheme: dark)'
  ).matches

  const [solution, setSolution] = useState(getWordOfDay(currentSolutionIndex - 1).solution)
  const [index, setIndex] = useState(getWordOfDay(currentSolutionIndex - 1).solutionIndex)
  const [currentGuess, setCurrentGuess] = useState('')
  const [isInfoModalOpen, setIsInfoModalOpen] = useState(false)
  const [isAboutModalOpen, setIsAboutModalOpen] = useState(false)
  const [isNotEnoughLetters, setIsNotEnoughLetters] = useState(false)
  const [isStatsModalOpen, setIsStatsModalOpen] = useState(false)
  const [isWordNotFoundAlertOpen, setIsWordNotFoundAlertOpen] = useState(false)
  const [isGameWon, setIsGameWon] = useState<{ solution: string, status: boolean | undefined }>({
    solution: solution,
    status: undefined,
  })

  const [isDarkMode, setIsDarkMode] = useState(
    localStorage.getItem('theme')
      ? localStorage.getItem('theme') === 'dark'
      : prefersDarkMode
        ? true
        : false
  )


  const [successAlert, setSuccessAlert] = useState('')
  const [guesses, setGuesses] = useState<{ index: number, guesses: string[] }>(() => {
    const loaded = loadGameStateFromLocalStorage(index)
    if (loaded?.solution !== solution) {
      return {
        index: 0,
        guesses: [],
      }
    }
    const gameWasWon = loaded.guesses.includes(solution)
    if (gameWasWon) {
      setIsGameWon({
        solution: loaded.solution,
        status: true,
      })
    }
    if (loaded.guesses.length === 6 && !gameWasWon) {
      setIsGameWon({
        solution: loaded.solution,
        status: false,
      })
    }
    return {
      index: loaded.index,
      guesses: loaded.guesses,
    }
  })

  // call this func from archive buttons
  const getPreviousWord = (prevIndex: number) => {
    const prevGuess = '';
    const prevGuesses = { index: prevIndex, guesses: loadGameStateFromLocalStorage(prevIndex)?.guesses ?? [] }
    const prevSolution = getWordOfDay(prevIndex)
    const status = () => {
      if (loadGameStateFromLocalStorage(prevSolution.solutionIndex)?.guesses.includes(prevSolution.solution) == true)
        return true // game was won
      else if (loadGameStateFromLocalStorage(prevSolution.solutionIndex)?.guesses.length === 6)
        return false // game was lost
      else
        return undefined // game was not finished
    }
    setCurrentGuess(prevGuess);
    setGuesses(prevGuesses);
    setSolution(prevSolution.solution);
    setIndex(prevSolution.solutionIndex)
    setIsGameWon({
      solution: prevSolution.solution,
      status: status(),
    })
  }

  const [stats, setStats] = useState(() => loadStats())
  useEffect(() => {
    if (isDarkMode) {
      document.documentElement.classList.add('dark')
    } else {
      document.documentElement.classList.remove('dark')
    }
  }, [isDarkMode])

  const handleDarkMode = (isDark: boolean) => {
    setIsDarkMode(isDark)
    localStorage.setItem('theme', isDark ? 'dark' : 'light')
  }

  useEffect(() => {
    saveGameStateToLocalStorage({ index, guesses: guesses.guesses, solution })
  }, [guesses.guesses, solution])

  useEffect(() => {
    if (isGameWon.status) {
      setSuccessAlert(
        WIN_MESSAGES[Math.floor(Math.random() * WIN_MESSAGES.length)]
      )
      setTimeout(() => {
        setSuccessAlert('')
        //setIsStatsModalOpen(true)
      }, ALERT_TIME_MS)
    }
    if (!isGameWon.status) {
      setTimeout(() => {
        // setIsStatsModalOpen(true)
      }, ALERT_TIME_MS)
    }
  }, [isGameWon.status, solution])

  const onChar = (value: string) => {
    if (currentGuess.length < 5 && guesses.guesses.length < 6 && !isGameWon.status) {
      setCurrentGuess(`${currentGuess}${value}`)
    }
  }

  const onDelete = () => {
    setCurrentGuess(currentGuess.slice(0, -1))
  }

  const onEnter = () => {
    if (isGameWon.status || !isGameWon) {
      return
    }
    if (!(currentGuess.length === 5)) { // works
      setIsNotEnoughLetters(true)
      return setTimeout(() => {
        setIsNotEnoughLetters(false)
      }, ALERT_TIME_MS)
    }

    if (!isWordInWordList(currentGuess)) { // works
      setIsWordNotFoundAlertOpen(true)
      return setTimeout(() => {
        setIsWordNotFoundAlertOpen(false)
      }, ALERT_TIME_MS)
    }

    const winningWord = isWinningWord(solution, currentGuess)

    if (currentGuess.length === 5 && guesses.guesses.length < 6 && !isGameWon.status) {
      setGuesses({ index: index, guesses: [...guesses.guesses, currentGuess] })
      setCurrentGuess('')

      if (winningWord) {
        setStats(addStatsForCompletedGame(stats, guesses.guesses.length))
        return setIsGameWon({
          solution: solution,
          status: true,
        })
      }

      if (guesses.guesses.length === 5) {
        setStats(addStatsForCompletedGame(stats, guesses.guesses.length + 1))
        setIsGameWon({
          solution: solution,
          status: false,
        })
      }
    }
  }

  return (
    <div className="py-8 max-w-7xl mx-auto sm:px-6 lg:px-8">
      <div className="flex w-80 mx-auto items-center mb-8 mt-12">
        <h1 className="text-xl grow font-bold dark:text-white">{GAME_TITLE}</h1>
        <SunIcon
          className="h-6 w-6 cursor-pointer dark:stroke-white"
          onClick={() => handleDarkMode(!isDarkMode)}
        />
        <InformationCircleIcon
          className="h-6 w-6 cursor-pointer dark:stroke-white"
          onClick={() => setIsInfoModalOpen(true)}
        />
        <ChartBarIcon
          className="h-6 w-6 cursor-pointer dark:stroke-white"
          onClick={() => setIsStatsModalOpen(true)}
        />
      </div>
      <div className="flex w-80 mx-auto items-center mb-4 mt-4">
        <ArchiveButtons getPreviousWord={getPreviousWord} />
      </div>
      <Grid guesses={guesses.guesses} currentGuess={currentGuess} solution={solution} />
      <Keyboard
        onChar={onChar}
        onDelete={onDelete}
        onEnter={onEnter}
        guesses={guesses.guesses}
        solution={solution}
      />
      <InfoModal
        isOpen={isInfoModalOpen}
        handleClose={() => setIsInfoModalOpen(false)}
      />
      <StatsModal
        isOpen={isStatsModalOpen}
        handleClose={() => setIsStatsModalOpen(false)}
        guesses={guesses.guesses}
        gameStats={stats}
        isGameLost={isGameWon.status ? true : false}
        isGameWon={isGameWon.status ? true : false}
        solution={solution}
        handleShare={() => {
          setSuccessAlert(GAME_COPIED_MESSAGE)
          return setTimeout(() => setSuccessAlert(''), ALERT_TIME_MS)
        }}
      />
      <AboutModal
        isOpen={isAboutModalOpen}
        handleClose={() => setIsAboutModalOpen(false)}
      />

      <button
        type="button"
        className="mx-auto mt-8 flex items-center px-2.5 py-1.5 border border-transparent text-xs font-medium rounded text-indigo-700 bg-indigo-100 hover:bg-indigo-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 select-none"
        onClick={() => setIsAboutModalOpen(true)}
      >
        {ABOUT_GAME_MESSAGE}
      </button>

      <Alert message={NOT_ENOUGH_LETTERS_MESSAGE} isOpen={isNotEnoughLetters} />
      <Alert
        message={WORD_NOT_FOUND_MESSAGE}
        isOpen={isWordNotFoundAlertOpen}
      />
      <Alert message={CORRECT_WORD_MESSAGE(solution)} isOpen={isGameWon.status == false} />
      <Alert
        message={successAlert}
        isOpen={false} // successAlert !== ''
        variant="success"
      />
    </div>
  )
}

export default App
