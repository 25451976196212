export const WORDS = [
  'çfarë',
  'fshat',
  'oqean',
  'akull',
  'anije',
  'avull',
  'ballë',
  'banim',
  'banor',
  'baski',
  'birrë',
  'borxh',
  'branë',
  'bravë',
  'bredh',
  'bretk',
  'brimë',
  'brisk',
  'britm',
  'bronz',
  'brumë',
  'qytet',
  'brymë',
  'buall',
  'bujar',
  'bukur',
  'bukël',
  'bullë',
  'bunar',
  'punoj',
  'burim',
  'burmë',
  'burri',
  'burrë',
  'bursë',
  'butik',
  'byrek',
  'vepër',
  'çelës',
  'forcë',
  'këngë',
  'nesër',
  'poezi',
  'folje',
  'ëmbël',
  'makth',
  'oxhak',
  'liria',
  'shegë',
  'guxim',
  'beqar',
  'fqinj',
  'çfarë',
  'frazë',
  'kanun',
  'sfidë',
  'mekem',
  'diell',
  'pirun',
  'gëzim',
  'mulli',
  'errët',
  'gdhij',
  'fruti',
  'katër',
  'rreze',
  'thelb',
  'kurth',
  'pendë',
  'lutje',
  'gacat',
  'njësi',
  'burrë',
  'godit',
  'thirr',
  'fshij',
  'dritë',
  'pjesë',
  'formë',
  'burrë',
  'fjalë',
  'gatim',
  'kopër',
  'furrë',
  'numër',
  'haset',
  'thanë',
  'malok',
  'ironi',
  'drekë',
  'prill',
  'lukth',
  'tiran',
  'burim',
  'qytet',
  'gjini',
  'hallë',
  'tremë',
  'ulëse',
  'çerek',
  'debat',
  'fabul',
  'kakao',
  'bluzë',
  'rrobë',
  'gjuhë',
  'jaran',
  'kartë',
  'libër',
  'ketër',
  'çekiç',
  'aromë',
  'klimë',
  'lepur',
  'flakë',
  'selit',
  'pjatë',
  'liman',
  'lodër',
  'luftë',
  'lëndë',
  'madhe',
  'xhami',
  'martë',
  'botim',
  'ikonë',
  'trofe',
  'pendë',
  'qoshe',
  'postë',
  'oborr',
  'ngërç',
  'barkë',
  'gjobë',
  'festë',
  'tituj',
  'bardh',
  'pishë',
  'sport',
  'tetor',
  'vajzë',
  'vegël',
  'zemër',
  'çelës',
  'çorap',
  'islam',
  'irizë',
  'cikël',
  'halth',
  'fanar',
  'bravë',
  'hallë',
  'halli',
  'iridë',
  'ftesë',
  'djall',
  'halëz',
  'bukël',
  'dhëmb',
  'branë',
  'istëm',
  'jahni',
  'kallo',
  'etikë',
  'ironi',
  'irnim',
  'hamam',
  'korbë',
  'hajni',
  'kalli',
  'haliç',
  'cipëz',
  'plagë',
  'kodër',
  'diell',
  'akull',
  'flamë',
  'rrufe',
  'banor',
  'avull',
  'rrugë',
  'klith',
  'zjarr',
  'bronz',
  'vathë',
  'parim',
  'tharm',
]
