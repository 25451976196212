import { Cell } from '../grid/Cell'
import { BaseModal } from './BaseModal'

type Props = {
  isOpen: boolean
  handleClose: () => void
}

export const InfoModal = ({ isOpen, handleClose }: Props) => {
  return (
    <BaseModal title="Si të luash" isOpen={isOpen} handleClose={handleClose}>
      <p className="text-sm text-gray-500 dark:text-gray-300">
        Gjeje me mend fjalën në 6 përpjekje. Pas çdo supozimi, ngjyra e pllakave
        do ndryshon për të treguar se sa afër ishte supozimi juaj me fjalën.
      </p>

      <div className="flex justify-center mb-1 mt-4">
        <Cell value="M" status="correct" />
        <Cell value="O" />
        <Cell value="L" />
        <Cell value="L" />
        <Cell value="Ë" />
      </div>
      <p className="text-sm text-gray-500 dark:text-gray-300">
        Shkronja M është në fjalën dhe vendin e duhur.
      </p>

      <div className="flex justify-center mb-1 mt-4">
        <Cell value="P" />
        <Cell value="I" />
        <Cell value="L" status="present" />
        <Cell value="O" />
        <Cell value="T" />
      </div>
      <p className="text-sm text-gray-500 dark:text-gray-300">
        Shkronja është në fjalë por në vendin e gabuar.
      </p>

      <div className="flex justify-center mb-1 mt-4">
        <Cell value="L" />
        <Cell value="O" />
        <Cell value="D" />
        <Cell value="Ë" status="absent" />
        <Cell value="R" />
      </div>
      <p className="text-sm text-gray-500 dark:text-gray-300">
        Shkronja nuk është në fjalën aspak.
      </p>
    </BaseModal>
  )
}
