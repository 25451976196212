import { BaseModal } from './BaseModal'

type Props = {
  isOpen: boolean
  handleClose: () => void
}

export const AboutModal = ({ isOpen, handleClose }: Props) => {
  return (
    <BaseModal title="Rreth lojës" isOpen={isOpen} handleClose={handleClose}>
      <p className="text-sm text-gray-500 dark:text-gray-300">
        Kjo lojë u inspirua nga Wordle në gjuhën Angleze. Ndërtuar dhe përkthyer
        nga{' '}
        <a href="https://metinferati.com" className="underline font-bold">
          Metin Ferati
        </a>
        <br />
      </p>
    </BaseModal>
  )
}
