import { WORDS } from '../constants/wordlist'
import { VALIDGUESSES } from '../constants/validGuesses'

export const isWordInWordList = (word: string) => {
  return (
    WORDS.includes(word.toLowerCase()) ||
    VALIDGUESSES.includes(word.toLowerCase())
  )
}

export const isWinningWord = (solution: string, word: string) => {
  return solution === word
}

export const getWordOfDay = (index: number) => {
  // January 1, 2022 Game Epoch
  const epochMs = new Date('December 31, 2021 23:00:00').valueOf()
  const now = Date.now()
  const msInDay = 86400000
  const currentIndex = Math.floor((now - epochMs) / msInDay);
  const nextday = (currentIndex + 1) * msInDay + epochMs
  return {
    solution: WORDS[index % WORDS.length].toUpperCase(),
    currentSolutionIndex: currentIndex,
    solutionIndex: index,
    tomorrow: nextday,
  }
}

export const { solution, solutionIndex, currentSolutionIndex, tomorrow } = getWordOfDay(0)


