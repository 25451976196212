
import { useState, useEffect } from 'react';
import { currentSolutionIndex } from '../../lib/words';
import {
    ArrowNarrowRightIcon,
    ArrowNarrowLeftIcon,
} from '@heroicons/react/outline';
type Props = {
    getPreviousWord: (index: number) => void
}

export const ArchiveButtons = ({ getPreviousWord }: Props) => {
    const [index, setIndex] = useState(currentSolutionIndex - 1);

    useEffect(() => {
        getPreviousWord(index)
    }, [index])

    return (
        <ul className="flex w-full justify-center">
            <li className="flex-1 mr-1">
                <button
                    type="button"
                    className="w-full text-center px-2.5 py-1.5 border border-transparent text-xs font-medium rounded text-black dark:text-white bg-slate-200 dark:bg-slate-600 hover:bg-slate-300 active:bg-slate-400 select-none"
                    onClick={() => setIndex(0)}
                >
                    Fillimi
                </button>
            </li>
            <li className="flex-2 mr-1">
                <button
                    type="button"
                    className={`w-full text-center px-2.5 py-1.5 border border-transparent text-xs font-medium rounded text-black dark:text-white active:bg-slate-400 select-none ${index == 0 ? 'pointer-events-none bg-slate-400' : 'bg-slate-200 dark:bg-slate-600'}`}
                    disabled={index == 0 ? true : undefined}
                    onClick={() => setIndex(index - 1)}
                >
                    <ArrowNarrowLeftIcon
                        className='h-4 w-5 dark:stroke-white'
                    />
                </button>
            </li>
            <li className="flex-1 mr-1">
                <select className='w-full appearance-none px-2.5 py-1.5 border border-transparent text-xs font-medium rounded text-black dark:text-white bg-slate-200 dark:bg-slate-600 hover:bg-slate-300 active:bg-slate-400 select-none' value={index} onChange={e => setIndex(Number(e.target.value))}>
                    {[...Array(currentSolutionIndex)].map((_, i) => (
                        <option className='text-center' key={i} value={i}>#{i + 1}</option>
                    ))}

                </select>
            </li>
            <li className="flex-2 mr-1">
                <button
                    type="button"
                    className={`w-full text-center px-2.5 py-1.5 border border-transparent text-xs font-medium rounded text-black dark:text-white active:bg-slate-400 select-none ${index == currentSolutionIndex - 1 ? 'pointer-events-none bg-slate-400' : 'bg-slate-200 dark:bg-slate-600'}`}
                    disabled={index == currentSolutionIndex - 1 ? true : undefined}
                    onClick={() => setIndex(index + 1)}
                >
                    <ArrowNarrowRightIcon
                        className='h-4 w-5 dark:stroke-white'
                    />
                </button>
            </li>
            <li className="flex-1">
                <button
                    type="button"
                    className="w-full text-center px-2.5 py-1.5 border border-transparent text-xs font-medium rounded text-black dark:text-white bg-slate-200 dark:bg-slate-600 hover:bg-slate-300 active:bg-slate-400 select-none"
                    onClick={() => setIndex(currentSolutionIndex - 1)}
                >
                    Fundi
                </button>
            </li>
        </ul>
    )
}
